import React from 'react'
import { Row,Col,  Container } from 'react-bootstrap';
import Routes from '../common/routes';

class RightSideComponents extends React.Component{

    render(){
        let isLoggedInGateway = JSON.parse(localStorage.getItem('isLoggedInGateway'))
        return(
            
                <Routes/>
            
        )
    }

}

export default RightSideComponents