import React from 'react'
import { Redirect, Route, withRouter } from 'react-router-dom'


const PrivateRoute = ({component: Component, ...rest }) => {

    let isLoggedInGateway = localStorage.getItem('isLoggedInGatewayOnBoard');
    let windowWidth= window.innerWidth
    // localStorage.setItem('userLoginDetailsOnBoard', JSON.stringify(res.data))
    // localStorage.setItem('isLoggedInGatewayOnBoard', true);

    return (
        <Route onEnter={() => window.analytics()} {...rest} render={props => (
            isLoggedInGateway && isLoggedInGateway == 'true' ? (
                <Component {...props}/>
              ) :windowWidth && windowWidth > 767?  (
                <Redirect to={{
                  pathname: '/login',
                }}/>
              ):(
                <Redirect to={{
                  pathname: '/login-mobile',
                }}/>
              )
          
        )}/>
    )
}

export default (PrivateRoute) 