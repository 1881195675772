import React from 'react';
import { Link, withRouter } from 'react-router-dom'

import { sidebarMenuItems } from '../common/menuItems';
import { getTranslatedText } from '../common/utilities';



class Aside extends React.Component {

    getActiveTab = (eachMenuItem) => {



        let className = ''

        if (this.props.location.pathname.includes(eachMenuItem.paths)) {
            className = 'nav-item active'
        }else{
            className = 'nav-item'
        }

        return className
    }

    showMenuItems = () => {
        let license = ""
        
        let arr = []
        for (let index = 0; index < sidebarMenuItems.length; index++) {
            const element = sidebarMenuItems[index];
            const  content = 
            
            
            <Link to={element.paths} className="nav-link" onClick={()=>this.props.ToggledMenu()} >
                <span className="shape1"></span><span className="shape2"></span>
                   <span className='sidemenu-icon'><element.icon /></span>
                    <span className={this.getActiveTab(element)}>
                        {getTranslatedText(element.title)}


                    </span>
                </Link>
         
        
                arr.push(
                    <li className={element.status=='inactive'?"disabled-li":this.getActiveTab(element)}  key={index}>{content}</li>
                )
        }
        return arr
            }


     
    render() {
        return (
            <div className='main-sidebar-body'>
                <ul className='nav'>
                    {this.showMenuItems()}
                </ul>
            </div>
        )
    }
}

export default withRouter(Aside)
