import React from "react";
import { Container,Col,Row } from "react-bootstrap";
import { translate } from "../../components/translation";

export default function DashboardStats(props){
    
    return(<div className="topStatsArea">
                <Row>
                    <Col lg={6} xs={6}>
                        <div className="bgWhite">
                            <div className="biggerCountArea" style={{color:"#000"}}>{props.totalTagDaily?props.totalTagDaily:0}</div>
                            <div className="smallerTitleArea">{translate("Daily Worker",props.language)}</div>
                            <div className="div100">
                                <div className="div50">{translate("Scan-In",props.language)} <span>{props.dailyData.scan_in?props.dailyData.scan_in:0}</span></div>
                                <div className="div50">{translate("Scan-Out",props.language)} <span>{props.dailyData.scan_out?props.dailyData.scan_out:0}</span></div>
                            </div>
                            {/* <div className="smallerSubTitleArea">{"Check-In"}</div> */}
                        </div>
                    </Col>
                    <Col lg={6}  xs={6}>
                    <div className="bgWhite">
                        <div className="biggerCountArea" style={{color:"#f16b95"}}>{props.regularTagCheckIN?props.regularTagCheckIN:0}</div>
                        <div className="smallerTitleArea">{translate("Regular Worker",props.language)}</div>
                            <div className="div100">
                                <div className="div50">{translate("Scan-In",props.language)} <span>{props.regularData.scan_in?props.regularData.scan_in:0}</span></div>
                                <div className="div50">{translate("Scan-Out",props.language)} <span>{props.regularData.scan_out?props.regularData.scan_out:0}</span></div>
                            </div>
                        {/* <div className="smallerSubTitleArea">{"Check-In"}</div> */}
                    </div>
                    </Col>
                   
                </Row>
        </div>)

}