import React from 'react';
import translations from '../assets/translations.json';

export const translate = (key,lang) => {

    if(lang === 'en'){

        return key
    }else{
        return translations[key] || key;
    }
};

// Usage in a React Component
