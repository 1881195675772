import logo from './logo.svg';
import { BrowserRouter } from 'react-router-dom';
import {withRouter} from 'react-router-dom'
import './App.css';
import './assets/styles/aside.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/common.css'
import './assets/styles/responsive.css'
import LeftSideBar from './components/leftsidebar';
import RightSideComponents from './components/rightsideComponents';
import TopHeader from './components/topHeader';

import React, { useEffect, useState } from 'react'


function App() {

  let isLoggedInGateway = JSON.parse(localStorage.getItem('isLoggedInGateway'))

  const [menuActive,SetMenuActive]=useState(false);

  function ToggledMenu(){
    if(menuActive){
      
      document.body.classList.add('main-sidebar-show')
      
    }else{
      document.body.classList.remove('main-sidebar-show')
    }
    SetMenuActive(!menuActive)
    
  }
  return (
    
      <div className="mainAppDiv">
    
      {
        isLoggedInGateway ? 
        <><LeftSideBar menuActive={menuActive} ToggledMenu={ToggledMenu} /> <TopHeader menuActive={menuActive} ToggledMenu={ToggledMenu} /></>: ''
      }

        <RightSideComponents />
      </div>
    
  );
}



export default withRouter(App);
