import React, { useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { Form, Input, Button, Checkbox } from 'antd';
import DatePicker from 'react-date-picker';
import  LoginIcon from '../../assets/images/login.svg'
//import 'antd/dist/antd.css'; 

import '../styles/login.css'

import { emailPattern } from '../../common/utilities';
import { userLogin, forgotPassword } from '../actionMethods/actionMethods';

import traceplusLogo from '../../assets/traceplusImages/trace_logo.png'
import showPasswordEyeIcon from '../../assets/traceplusImages/show_password.png'


import infiniteLoader from '../../assets/images/infinite_loader.gif'



function LoginMobile(props) {

    const [emailID, updateEmailID] = useState('')
    const [password, updatePassword] = useState('')

    const [isEmailValid, updateIsEmailValid] = useState(true)
    const [isPasswordEmpty, updateIsPasswordEmpty] = useState(false)

    const [isForgotPasswordView, updateIsForgotPasswordView] = useState(false)

    const [somethingWentWrongFlag, updateSomethingWrongWentFlag] = useState(false)
    const [errorMessage, updateErrorMessage] = useState('')

    const [successMessage, updateSucessMessage] = useState('')

    const [isLoading, updateIsLoading] = useState(false)
    function randomString(length, chars) {
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
      } 

    
    function handleSubmit(event) {
        event.preventDefault()

        if (emailID == '') {
            updateIsEmailValid(false)
        }

        if (password == '') {
            updateIsPasswordEmpty(true)
        }


        if (emailID && password) {

            let authToken =randomString(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ')
                            updateIsLoading(true)
                updateIsPasswordEmpty(false)
                updateIsEmailValid(true)
                let requestBody = {}
                requestBody.username = emailID
                requestBody.password = password
                requestBody.token = authToken

                userLogin(requestBody).then(res=>{

                    console.log("login response",res)
                        if(res.data.status==200){
                            localStorage.setItem('userLoginDetailsOnBoard', JSON.stringify(res.data))
                            localStorage.setItem('isLoggedInGatewayOnBoard', true);
                            localStorage.setItem('userOnBoardLanguage', 'en');
                            props.history.push('/dashboard-mobile')        
                            localStorage.setItem('tokenAuthTraceOnBoard', authToken)
                        }else{
                            updateIsLoading(false)
                            updateSomethingWrongWentFlag(true)
                            updateErrorMessage(res.data.message)           
                            setTimeout(() => {
                                updateSomethingWrongWentFlag(false)
                            }, 3000);


                        }
    
                })
                setTimeout(function(){
                    updateIsLoading(false)
                },3000)


    
        }
    }

    function toggleForgotPasswordView(flag) {
        updateIsForgotPasswordView(flag)
        updateEmailID('')
        updatePassword('')
        updateIsPasswordEmpty(false)
        updateIsEmailValid(true)
        updateErrorMessage('')
        updateSucessMessage('')
    }


    function togglePasswordTypeChange() {
        if (document.getElementById('password')) {
            document.getElementById('password').type == 'text' ? document.getElementById('password').type = "password" : document.getElementById('password').type = 'text'
        }
    }



    function handleEmailID(value) {
        updateEmailID(value)
    }

    function handlePassword(value) {
        updatePassword(value)
        updateIsPasswordEmpty(false)
    }

    let isLoggedInGateway = JSON.parse(localStorage.getItem('isLoggedInGatewayOnBoard'))

    if (isLoggedInGateway) {
        return (
            <div>
                {props.history.push('/dashboard-mobile')}
            </div>
        )
    }

    else {

        return (
            <div className="loginComponentMainDiv">
                <div className="firstRowDiv">

                </div>

                <div className="secondRowDiv">

                </div>

                <div className="loginFormWithLogoDiv" style={{transform:"translate(-50%, -50%)"}}>

                    <Row>
                        <Col lg={3}>
                        </Col>
                        <Col lg={6}>
                            <div className="logoDiv">
                                <img src={traceplusLogo} />
                                {/* <img src={LoginIcon} width="200px"/> */}
                            </div>
                            {/* <div className="loginFormMainDiv loginFormMainDivMobile" style={{margin:0,padding:"1rem"}}> */}
                            <div className="loginFormMainDiv">
                                {
                                    !isForgotPasswordView ?

                                        <React.Fragment>
<div className="loginText">Login</div>
                                            {/* <div className="loginText">Welcome To Onboard</div>
                                            <div className="loginSmallText">Please Login to continue</div> */}
                                            <div className="loginForm">
                                                <form onSubmit={handleSubmit}>
                                                    <div className="eachElement">
                                                        <label>User Name</label>
                                                        <input maxLength="150" type="text" name="email" value={emailID} onChange={(e) => handleEmailID(e.target.value)} placeholder="User Name" />

                                                        {
                                                            !isEmailValid ? <div className="dangerColor">Please Enter Username !</div> : ''
                                                        }
                                                    </div>

                                                    <div className="eachElement">
                                                        <label>Password</label>
                                                        <input maxLength="150" id="password" type="password" name="password" value={password}
                                                            onChange={(e) => handlePassword(e.target.value)} placeholder="Password" />
                                                        <img src={showPasswordEyeIcon} onClick={togglePasswordTypeChange} />

                                                        {
                                                            isPasswordEmpty ? <div className="dangerColor">Please Enter Password !</div> : ''
                                                        }
                                                    </div>


                                                    {
                                                        isLoading ?
                                                            <img src={infiniteLoader} /> :
                                                            <button type="submit" className="loginFormButton">Log In</button>

                                                    }

                                                    {
                                                        somethingWentWrongFlag ?

                                                            <div className="dangerColor text-center">{errorMessage}</div> : ''
                                                    }

                                                </form>

                                            </div>
                                        </React.Fragment> : ""

                                         }
                            </div>
                        </Col>
                        <Col lg={3}>
                        </Col>
                    </Row>

                </div>
            </div>
        )

    }
}


export default connect(null, {  })(withRouter(LoginMobile))