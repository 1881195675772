import React,{useState} from 'react'
import { getTranslatedText } from './utilities'
import {ReactComponent as PowerOff} from '../assets/images/power-off-solid.svg'
import {systemrestart} from '../dashboard/actionMethods/actionMethods'
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useEffect } from 'react';
import {logoutUser} from '../login/actionMethods/actionMethods'
import LogoutIcon from '../assets/images/right-from-bracket.svg'
import traceplusLogo from '../assets/traceplusImages/trace_logo.png'
import { Modal  } from 'antd';
import {Select} from 'antd';
import { translate } from '../components/translation';

export function CommonHeading(props){
    const {Option} = Select
    const [language,SetLanguage]= useState(props.language?props.language:'en');
    
    let userDetails = localStorage.getItem('userLoginDetailsOnBoard')?JSON.parse(localStorage.getItem('userLoginDetailsOnBoard')):[];
    let history =useHistory();
    const [time,SetTime] =useState(moment().format('DD MMM YYYY HH:mm:ss'));    
    const [modalLogout,SetModalLogout] =useState(false);

    function handlerLogout(){

        let requestBody = {}
        requestBody.token = localStorage.getItem('tokenAuthTrace')?localStorage.getItem('tokenAuthTrace'):'tituw3958589'
        requestBody.session = userDetails.session?userDetails.session:'123456789'
        
        logoutUser(requestBody).then(res => {
            if (res && res.status == 200) {
                    SetModalLogout(false);     
                    //updateShowLoader(false);
                    localStorage.removeItem('isLoggedInGatewayOnBoard')
                    localStorage.removeItem('userLoginDetailsOnBoard')
                    localStorage.removeItem('selectedDate')
                    localStorage.removeItem('tokenAuthTraceOnBoard')
                    history.push(`/login`)
            }
        })   
    }


    


    const timerLive = ()=>{
        SetTime(moment().format('DD MMM YYYY HH:mm:ss'));
      }
      useEffect(() => {
        const id3 = setInterval(timerLive, 1000);
        return () =>{
          clearInterval(id3);
          }   
        },[]);

   


return(
        <div className="page-header row">

            <div className='col-lg-2 col-xs-6 col-5'>
                    <div className='logoArea'>
                        <img
                        src={userDetails && userDetails.org_logo ? userDetails.org_logo : traceplusLogo}
                        className="logo"
                        alt="TracePlus Logo"
                        onError={(e) => {
                            e.target.src = traceplusLogo
                        }}
                    />
                    <div className='smallTagLine smallTaglineResp'>{props.title}</div>
                    </div>
            </div>

            <div className='col-lg-6 col-sm-6 col-xs-6 col-6 hideResp'>
                <h2 className="main-content-title tx-24 mg-b-5">{props.MainTitle}</h2>
                <ol className="breadcrumb">
                    {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                    <li className="breadcrumb-item active" aria-current="page">{props.title}</li>
                </ol>
               
            </div>

            <div className='rightDivHeader col-lg-4 col-sm-6 col-xs-12 col-7' style={{textAlign:"right"}}>
                <div className='smallLanguage'>
                    <Select
                        value={language}
                        onDropdownVisibleChange={()=>{ props.focusOff(); console.log("dropdown visible")}}
                        onChange={(val)=>{SetLanguage(val);props.languageChangeHandler(val)}}
                        onBlur={()=>props.languageChangeBlur()}
                        width={100}
                        maxWidth={100}
                        style={{width:100,padding:0}}
                    >
                        <Option value={"en"}>English</Option>
                        <Option value={"ind"}>Bahasa</Option>
                    </Select>
                </div>
                <div className='hourStyle'>{time}</div>
                <div className='btnLogout btntransparent' onClick={()=>SetModalLogout(true)}>
                    <button className='btn btnLogout'><img src={LogoutIcon}/></button>
                </div>
            </div>
 <Modal
          title="Logout"
          open={modalLogout}
          okText="Logout"
          cancelText="Cancel"
          onOk={handlerLogout}
          onCancel={()=>SetModalLogout(false)}
         
          className="logoutModal"
          //className="modalStyle"
          // cancelButtonProps={{ disabled: true }}
        >
          <div className='formField formFieldUnderModal'>
           <h2>{translate('Logout',language)}</h2> 
           <p>{translate('Are you sure you want to logout',language)}</p>
    
        </div>
        </Modal>


        </div>)
    
}