import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Aside from './aside';

import traceplusLogo from '../assets/traceplusImages/trace_logo.png';
import safeairlogo from '../assets/images/safeair-logo-black.png'


import SpinnerLoader from '../assets/images/Spinner Loader.gif'
import {ReactComponent as LogoutIcon} from '../assets/images/arrow-right-from-bracket-solid.svg'
import moment from 'moment';
import { useHistory } from 'react-router-dom';


function TopHeader(props) {
let history = useHistory();
   // let userDetails = JSON.parse(localStorage.getItem('userLoginDetailsGateway'))

   const [showLoader, updateShowLoader] = useState(false)
   const [showLogout, setShowLogout] = useState(false)
    const [timer,SetTimer]=useState(''); 

    let manufacturer = localStorage.getItem('manufacturer');

   const handleLogoutModal = () => {
	setShowLogout(false)
}
const [menuActive,SetMenuActive]=useState(false);
useEffect(()=>{
setInterval(function(){
    SetTimer(moment().format('YYYY MM DD hh:mm:ss A'))
},1000)
},[])
function MenuChange(){
    SetMenuActive(!menuActive);
}
   function handleLogout() {
	updateShowLoader(true)
	
   
				

}

    return (
        <React.Fragment>
            <div className="jumps-prevent" style={{paddingTop: "64.2px"}}></div>
			<div className="main-header side-header sticky">

				
				<div className="container-fluid">
					
					<div className={manufacturer!=null && manufacturer!='' && manufacturer=='SafeAir'?"main-header-center main-header-center-safeair":"main-header-center"}>
						<div className="responsive-logo">
							<a href="/dashboard">
                            {manufacturer!=null && manufacturer!='' && manufacturer=='SafeAir'?
                                <img src={safeairlogo} className="mobile-logo" alt="logo"/>:
                                <img src={traceplusLogo} className="mobile-logo" alt="logo"/>
                            }
                            </a>
						
						</div>
						
					</div>
					
					<div className="main-header-right">
						<div className="row">
							<div className="col-md-4">Trace+ IoT Gateway</div>
							<div className="col-md-8">							
							<div className="header-right" id="">
						  <ul className="navbar-nav">
							<li> <a className="nav-link navbar-color"> System Time: </a><span className='timercolor'> {timer}</span> </li>
							{/* <li> <a className="nav-link navbar-color"> Build: </a> </li> */}
							{/* <li> <a className="nav-link navbar-color">  </a></li> */}
							{/* <li> <a className="nav-link navbar-color" href="#"> Help </a></li> */}
							<li> <a className="nav-link navbar-color" href="#" onClick={()=>setShowLogout(true)}><span className='btn-shape'><LogoutIcon/>Logout</span></a> </li>
						  </ul>
						</div>
					</div>	
						
						
						
						</div>
					</div>
					<div className="main-header-left">
						<a className={props.menuActive?"main-header-menu-icon close-icon":"main-header-menu-icon" }  href="#" id="mainSidebarToggle"  onClick={()=>props.ToggledMenu()}><span></span></a>
					</div>
				</div>
			</div>
			


        </React.Fragment>
    )
}



export default connect(null, {})(withRouter(TopHeader))

