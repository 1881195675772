import ExcelJs from "exceljs";
import moment from "moment";


export default function Export(props){

    const exportToExcel = () => {
        let sheetName = "Onboard";
        let headerName= "Report"
        const filename = "OnBoard Report"+"-"+moment(props.start_date).format("MMM DD")+"-"+moment(props.end_date).format("MMM DD")+".xlsx";
        const workbook = new ExcelJs.Workbook();
        let loc = [];
        
      
        let worksheet = workbook.addWorksheet('Onboard');
        let cols =[
          {name:'Date', header: 'check_in', key: 'check_in'},
          {name:'Name', header: 'Name', key: 'name'},
          {name:'Worker ID', header: 'Worker ID', key: 'worker_id'},
          {name:'Tag Serial', header: 'Tag Serial', key: 'tag_serial'},
          {name:'Type', header: 'Type', key: 'type'},
          {name:'Department', header: 'Department', key: 'department'},
          {name:'Function', header: 'Function', key: 'function'},
          {name:'Check-In', header: 'Check-In', key: 'check_in'},
          {name:'Check-Out', header: 'Check-Out', key: 'check_out'},
          {name:'Status', header: 'Status', key: 'status'},
        ];

        worksheet.addTable({
          name: sheetName,
          ref: "A1", 
          headerRow: true,
          totalsRow: false,
          style: {
            theme: "TableStyleMedium2",
            showRowStripes: false,
            width: 200
          },
          columns : cols,
          rows: props.data.map((item) => {
            console.log("export",item )
            let cOut=item.check_out=='-'||item.check_out==null?"-":moment(item.check_out).format('hh:mm A');
            let status="";
            if(item.status=="red"){
              status = "short shift"
            } else if(item.status =="orange"){
              status = "Non checkout"
            }else if(item.status=="green"){
              status = "Complied"
            }
            let arr =[moment(item.check_in).format('DD MMM YYYY'),item.name,item.worker_id,item.tag_serial,item.type,item.department,item.function,moment(item.check_in).format('hh:mm A'), cOut,status];
           
      
                        return arr;
          })
        })
      
        worksheet.state = 'visible';
        const table = worksheet.getTable(sheetName);
        for (let i = 0; i < table.table.columns.length; i++) {
            worksheet.getCell(`${String.fromCharCode(65 + i)}1`).font = { size: 11 };
            worksheet.getCell(`${String.fromCharCode(65 + i)}1`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "d0cfcf" }
          };
      
      
        }
        worksheet.columns.forEach(column => {
          const lengths = column.values.map(v => v.toString().length);
          const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
          column.width = maxLength+2;
        });
        
        table.commit();
        const writeFile = (fileName, content) => {
          const link = document.createElement("a");
          const blob = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8;"
          });
          link.download = fileName;
          link.href = URL.createObjectURL(blob);
          link.click();
        };
      //const stream = fs.createWriteStream(filename);
      
      workbook.xlsx.writeBuffer().then((buffer) => {
          writeFile(filename, buffer);
      });
      
      
      
      
        
      
      
      
      };
      
      return(
        <button className='btn btn-pink btn-responsive' onClick={exportToExcel} style={{maxWidth:"40%",float:"right"}}>Export</button>
      )
}