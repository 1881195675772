import axios from "axios";


let  prefixURL = process.env.REACT_APP_URL
let  prefixURL2 = process.env.REACT_APP_URL_LOGIN

export function getOnBoardTag( session,org_id,sub_org_id) {
    return axios.get(prefixURL + `/onboarding/get_onboarding_details?session=${session}&org_id=${org_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)     
}

export function getProductivitylist(requestBody, sessionID, id,sub_org_id) {
    return axios.get(prefixURL + `/onboarding/regular_employee_map?session=${sessionID}&org_id=${id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)

}

export function getfunctionList(sessionID, id,sub_org_id) {
    return axios.get(prefixURL + `/onboarding/get_function_list?session=${sessionID}&org_id=${id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}

export function postCreadonBoard(requestBody) { 
    console.log("postCreadonBoard",requestBody)

    return axios.post(prefixURL + `/onboarding/employee_checkin`,requestBody)
        .then(res => res.data).catch(err => err)
}

export function getReportOnBoard(requestBody,sessionID, id,sub_org_id) {     
        return axios.get(prefixURL + `/onboarding/get_onboarding_report?session=${sessionID}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}

export function getWorkDayTime(sessionID, id,sub_org_id){
        return axios.get(prefixURL2 + `/organization/get_work_day_list?session=${sessionID}&org_id=${id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}
export function getAllFaces(){
        return axios.get(`https://192.168.1.11:5000/workers`)
        .then(res => res.data).catch(err => err)
}
