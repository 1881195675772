import { ReactComponent as DeviceManagementIcon }  from '../assets/images/tachometer-alt-solid.svg'
import { ReactComponent as NetworkSettingIcon }  from '../assets/images/cogs-solid.svg'


import { ReactComponent as TimeSettingIcon }  from '../assets/images/calendar-alt-regular.svg'
import  { ReactComponent as EyeIcon }  from '../assets/images/eye-solid.svg'
import { ReactComponent as SettingIcon }  from '../assets/images/screwdriver-wrench-solid.svg'


export const sidebarMenuItems = [
    {
        "title": "Gateway Status",
        "paths":"/gateway-status",
        "tooltip":"Dashboard",
        "status":"active",
        "icon":DeviceManagementIcon
    },
    {
        "title": "Network Settings",
        "paths":"/network-setting",
        "tooltip":"Site Management",
        "status":"active",
        "icon":NetworkSettingIcon
    },
    {
        "title": "Time Settings",
        "paths":"/time-setting",
        "icon":TimeSettingIcon
    },
    {
        "title": "Connection Watch Dog",
        "paths":"/connection-watch-dog",
        "tooltip":"Policy Management",
        "icon":EyeIcon
    },
    {
        "title": "Gateway Settings",
        "paths":"/gateway-setting",
        "status":"active",
        "icon":SettingIcon
    },

]