import React, { useState, useEffect,useRef } from 'react'
import { CommonHeading } from '../../common/commonHeadingMobile';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import '../../assets/styles/common.css'
import '../../assets/styles/responsive.css'
import '../styles/dashboard.css'


import helpIcon from '../../assets/traceplusImages/help-icon.png'
import pinkArrowIcon from '../../assets/traceplusImages/pink_outline_right_arrow_icon.svg'
import selectedPinkArrowIcon from '../../assets/traceplusImages/pink_right_arrow_icon.svg'

import moment from 'moment'
import 'antd/dist/antd.css';
import spinnerLoader from '../../assets/images/Spinner Loader.gif'
import overheating from '../../assets/images/overheating.png'
import {ReactComponent as MircroChip} from '../../assets/images/microchip-solid.svg'
import {ReactComponent as UpIcon} from '../../assets/images/arrow-up-solid.svg'
import {ReactComponent as DownIcon} from '../../assets/images/arrow-down-solid.svg'

import {getOnBoardTag,getProductivitylist,getfunctionList,postCreadonBoard,getReportOnBoard, getAllFaces } from '../actionMethods/actionMethods';
import "react-datepicker/dist/react-datepicker.css";
import { logoutUser } from '../../login/actionMethods/actionMethods';          
import axios from 'axios';
import { Select } from 'antd';
import DatePicker from "react-datepicker";
import { AgGridReact,AgGridColumn } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button, Modal , Space } from 'antd';
import Export from './export';
import DashboardStats from './DashboardStats';
import {ReactComponent as Edit} from '../../assets/images/edi.svg'
import {ReactComponent as Clear} from '../../assets/images/clearall.svg'
import WarningSign from '../../assets/images/warning.png'

import GreenFlag from '../../assets/images/greenflag.png'
import RedFlag from '../../assets/images/redflag.png'
import YellowFlag from '../../assets/images/orangeflag.png'
import { getWorkDayTime } from '../actionMethods/actionMethods';
import {translate} from '../../components/translation'
// import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { Scanner,boundingBox } from '@yudiel/react-qr-scanner';

import SoundCheckInSuccess from '../../assets/audio/check-in sucess.mp3'
import SoundCheckInFailed from '../../assets/audio/check-in failed.mp3'
import SoundCheckOutSuccess from '../../assets/audio/checkout sucess.mp3'
import SoundCheckOutFailed from '../../assets/audio/checkout failed.mp3'
import SoundEarlyCheckout from '../../assets/audio/early checkout.mp3'

import SoundValiDTagSerial from '../../assets/audio/validTagSerial.mp3'
import SoundValidWokerID from '../../assets/audio/validWorkerID.mp3'

import SoundSuccess from '../../assets/audio/success.mp3'
import SoundFailed from '../../assets/audio/failed.mp3'
import TraceLogo from '../../assets/traceplusImages/trace_logo.png'
import * as faceapi from 'face-api.js';
// import IScannerComponents from '@yudiel/react-qr-scanner';
import DummyFace from './dummyFaceScan.json'



function DashboardMobile(props) {

  
  let requestBody={};
  let userDetails = localStorage.getItem('userLoginDetailsOnBoard')?JSON.parse(localStorage.getItem('userLoginDetailsOnBoard')):[];
  let lan = localStorage.getItem('userOnBoardLanguage')?localStorage.getItem('userOnBoardLanguage'):'en';

  let sub_org_id =  userDetails.sub_org_id?userDetails.sub_org_id:'';
    const [language,SetLanguage] = useState(lan);
    const [workerID,SetWorkerID]=useState('')
    const [tagID,SetTagID]=useState('')
    const [Function,SetFunction]=useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [onBoardEmp,SetOnBoardEmp] =useState([])
    const [onBoardCall,SetOnboardCall] = useState(false);
    const [onBoardEmpDisplay,SetOnBoardEmpDisplay] =useState([])
    const [selectedEmp,SetSelectedEmp] = useState({});
    
    const [workerIDList,SetWorkerIDList]=useState([])
    const [dailyworkerIDList,SetDailyWorkerIDList]=useState([])

    const [workerTag,SetWorkerTag]=useState([])
    const [dailyworkerTag,SetDailyWorkerTag]=useState([])

    const [search,SetSearch]=useState('');
    const [message,SetMessage]=useState('');  
    const [messageErrorWorker,SetMessageErrorWorker]=useState('');  
    const [messageErrorTag,SetMessageErrorTag]=useState('');  
    const [messageErrorFunction,SetMessageErrorFunction]=useState('');  
    const [displayMessage,SetMessageDisplay]=useState(false)
    const [allemp,SetAllEmp] = useState([]);
    const [regularfunction,SetRegularFunction] = useState([]);
    const [dailyfunction,SetDailyFunction] = useState([]);
    const { Option } = Select;
    const [dates, setDates] = useState({
      start: null,
      end: null,
    });
    const elementRef =useRef(null);
    const [widthContainer,SetWidthContainer]=useState(0)
    const [tagAPI,SetTagAPI] =useState(false);
    const [checkInRegular,SetCheckInRegular] =useState(0)
    const [checkInDaily,SetCheckInDaily] =useState(0)
    const [checkOut,SetCheckOut] =useState(0)

    const [modalSearch,SetModalSearch]=useState('');
    const [inc,SetInc] =useState(1);
    const [maxDate,SetMaxDate] =useState(null)
    const [notMessage,SetNoteMessage] =useState('');
    const [modalLogout,SetModalLogout]=useState(false);
    const [searchCheckIn,SetSearchCheckIn]=useState('');
    const [focus,SetFocus] =useState(true);
    const [workingHourDate,SetWorkingHourDate]= useState({
      start: null,
      end: null,
    })
    const [apiEnable,SetAPIEnable]=useState(true);
    const [shiftShortModal,SetShiftShortModal] = useState(false);
    const [shortTime,SetShortTime]= useState('');
    const [nonCheckout,SetNonCheckout] = useState([]);
    const [pastDay,SetPastDay] = useState({start:null,end:null});
    const [dailyScanData,SetDailyScanData] = useState({scan_in:0,scan_out:0})
    const [regularScanData,SetRegularScanData] = useState({scan_in:0,scan_out:0})
    const [indonesiaTime,SetIndonesiaTime] = useState('');
    const [scanEnable,SetScanEnable] = useState(true);
    const [tracker, setTracker] = useState('boundingBox');
    const [firstClick,SetFirstClick] = useState(false);
    
    const checkInSucessRef = useRef(null);
    const checkInFailRef = useRef(null);

    const checkOutSucessRef = useRef(null);
    const checkOutFailRef = useRef(null);
    
    const earlyCheckoutRef = useRef(null);

    const validTagSerialRef= useRef(null);
    const validWorkerIDRef = useRef(null);
    
    const successRef = useRef(null);
    const failedRef = useRef(null);
    
    const videoRef = useRef(null);
    const canvasRef = useRef(null); // Ref for the canvas overlay
    const inProcessRef = useRef(false); // Use ref to store the last face descriptor
    const lastDescriptorRef = useRef(null); // Use ref to store the last face descriptor
    const lastDetectionTimeRef = useRef(0); // Use ref to store the last detection time
    const [isDetectionPaused, setIsDetectionPaused] = useState(false); // Flag to pause detection
    const [allFaceScan,SetAllFaceScan]= useState([]);



useEffect(() => {
    const loadModels = async () => {
        try {
            // Load the models sequentially to ensure they are all loaded before inference
            await faceapi.nets.ssdMobilenetv1.loadFromUri('/models');
            await faceapi.nets.faceLandmark68Net.loadFromUri('/models'); // Load landmark model
            await faceapi.nets.faceRecognitionNet.loadFromUri('/models'); // Load face recognition model
            console.log('Models loaded successfully');
            startVideo(); // Start video after models are loaded
        } catch (error) {
            console.error('Error loading models:', error);
        }
    };

    loadModels();

    return () => {
        if (videoRef.current && videoRef.current.srcObject) {
            videoRef.current.srcObject.getTracks().forEach(track => track.stop());
        }
    };
}, [videoRef]);

useEffect(() => {
        if (videoRef.current && workingHourDate.start && workingHourDate.end && tagAPI && onBoardCall && scanEnable && apiEnable && checkInSucessRef.current && checkInFailRef.current && checkOutFailRef.current && checkOutSucessRef.current && earlyCheckoutRef.current && successRef.current && failedRef.current && firstClick) {
            setTimeout(function(){
                startVideo();
            },1000);  
        }
    }, [videoRef,allFaceScan, workingHourDate.start, workingHourDate.end, tagAPI, onBoardCall, scanEnable, apiEnable, checkInSucessRef.current , checkInFailRef.current , checkOutFailRef.current , checkOutSucessRef.current , earlyCheckoutRef.current , successRef.current , failedRef.current , firstClick]);

 const startVideo = () => {
        navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                facingMode: 'environment'
            }
        })
            .then(stream => {
                videoRef.current.srcObject = stream;
                detectFace();
            })
            .catch(err => console.error("Error accessing webcam:", err));
    };

const detectFace = () => {
    const intervalId = setInterval(async () => {
        if (videoRef.current && !isDetectionPaused) {
            // Ensure models are loaded before running the detection
            if (!faceapi.nets.ssdMobilenetv1.isLoaded || !faceapi.nets.faceLandmark68Net.isLoaded || !faceapi.nets.faceRecognitionNet.isLoaded) {
                console.log('Models are not loaded yet');
                return;
            }

            const detections = await faceapi.detectAllFaces(
                videoRef.current,
                new faceapi.SsdMobilenetv1Options()
            ).withFaceLandmarks().withFaceDescriptors(); // Include landmarks and descriptors

            clearCanvas();
            console.log('Any face detected', detections.length > 0, detections);
            if (detections.length > 0) {
                const detectedFace = detections[0];
                const currentTime = Date.now();

                if (
                    (!isSameFace(detectedFace.descriptor, lastDescriptorRef.current) || currentTime - lastDetectionTimeRef.current >= 5000) && !inProcessRef.current
                ) {
                    inProcessRef.current = true;
                    console.log('True from 1');
                    handleFaceDetection(detectedFace);
                }
            }
        }
    }, 200); // Check every 200ms, adjust if necessary

    return () => clearInterval(intervalId);
};

    const isSameFace = (newDescriptor, lastDescriptor) => {
        if (!lastDescriptor) return false;

        const distance = faceapi.euclideanDistance(newDescriptor, lastDescriptor);
        const threshold = 0.6; // Standard threshold for face recognition
        return distance < threshold; // Consider it the same face if the distance is below the threshold
    };

    const handleFaceDetection = (detectedFace) => {
        lastDescriptorRef.current = detectedFace.descriptor; // Update ref with the detected face descriptor
        lastDetectionTimeRef.current = Date.now(); // Update ref with the current time
        setIsDetectionPaused(true);

        console.log("any new faces", detectedFace);
        // Call the API
        callApi(detectedFace);

        // Resume detection after 2 seconds
        setTimeout(() => {
            setIsDetectionPaused(false);
        }, 2000);
    };

    const callApi = (detectedFace) => {
        let matchWorkerID = null;
        let allFace = [...allFaceScan];

        for (let i = 0; i < allFace.length; i++) {
            let storedDescriptor = new Float32Array(JSON.parse(allFace[i].face_descriptor));
            const distance = faceapi.euclideanDistance(detectedFace.descriptor, storedDescriptor);
            const threshold = 0.6; // Standard threshold for face recognition

            if (distance < threshold) {
                matchWorkerID = allFace[i].worker_id;
                console.log("facescore", distance);
                break; // Breaks the loop as soon as a match is found
            }
        }
        if (matchWorkerID) {
            SetSearch(matchWorkerID);

        } else {
            playSound("User is not recognized on face Scan");
        }
    };

    // Function to clear the canvas
    const clearCanvas = () => {
        const context = canvasRef.current.getContext('2d');
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    };

    // Function to draw face outline
    const drawFaceOutline = (detection) => {
        const context = canvasRef.current.getContext('2d');
        const { x, y, width, height } = detection.box; // Extract the bounding box coordinates

        context.beginPath();
        context.rect(x, y, width, height); // Draw a rectangle
        context.lineWidth = 2;
        context.strokeStyle = 'green'; // Color of the outline
        context.stroke();
    };

const handleChange = (value) => {

};

const translateAMPM = (timeFormat,language) => {
  let translatedTime;
  if(language ==='en'){
    return timeFormat
  }
  if (timeFormat.includes('AM')) {
    translatedTime = timeFormat.replace('AM', 'Pagi');
  } else if (timeFormat.includes('PM')) {
    translatedTime = timeFormat.replace('PM', 'Sore/Malam');
  }
  return translatedTime;
};

    useEffect(()=>{
      let faceList = DummyFace;
      SetAllFaceScan(faceList);

     getWorkDayTime(userDetails.session,userDetails.org_id,userDetails.sub_org_id).then(res=>{
      
      if(res.status==200){
          let sHour =  res.data.start_hour.split(":");
          let eHour = res.data.end_hour.split(":");
          //same_day
      var date = moment(); 
      var timeAndDate = moment(date).set({"hour": eHour[0], "minute": eHour[1]});
    
      let dt={start:null,end:null}
      
      let message = `Work Day  ${moment().set({hour:sHour[0],minute:sHour[1]}).format("h:mm A")} to ${moment().set({hour:eHour[0],minute:eHour[1]}).format("h:mm A")}`
      
      SetIndonesiaTime(`${translate('Work Day','ind')}  ${translateAMPM(moment().set({hour:sHour[0],minute:sHour[1]}).format("h:mm A"),'ind')} - ${translateAMPM(moment().set({hour:eHour[0],minute:eHour[1]}).format("h:mm A"),language )}`)
      SetNoteMessage(message);


      if(timeAndDate <= moment() ){
        // let message = `Work Day ${moment(date).format('DD')} : ${moment(date).format('DD MMM')} ${moment().set({hour:sHour[0]}).format("hA")} to ${moment(date).add(1,'days').format('DD MMM')} ${moment().set({hour:eHour[0]}).format("hA")}`
        SetPastDay((prev) => ({ ...prev, [`start`]:moment(date).subtract(1,'days').toDate()}))
        SetPastDay((prev) => ({ ...prev, [`end`]:moment(date).subtract(1,'days').toDate()}))

        setDates((prev) => ({ ...prev, [`start`]:moment(date).toDate()  }));
        setDates((prev) => ({ ...prev, [`end`]:  moment(date).add(1,'days').toDate()}));   
        dt.start=moment(date).toDate();
        dt.end=moment(date).add(1,'days').toDate()
        SetWorkingHourDate((prev) => ({ ...prev, [`start`]:moment(date).set({"hour": sHour[0], "minute": sHour[1]})   }));
        SetWorkingHourDate((prev) => ({ ...prev, [`end`]:  moment(date).add(1,'days').set({"hour": eHour[0], "minute": eHour[1],second:59 }) }));
        SetMaxDate(moment(date).add(1,'days').toDate())
      }
      else if(eHour[0]== "23" && eHour[1] == "59"  && sHour[0]=="00" && sHour[1]=="00"){
        SetPastDay((prev) => ({ ...prev, [`start`]:moment(date).subtract(1,'days').toDate()}))
        SetPastDay((prev) => ({ ...prev, [`end`]:moment(date).subtract(1,'days').toDate()}))

        setDates((prev) => ({ ...prev, [`start`]:moment(date).toDate()  }));
        setDates((prev) => ({ ...prev, [`end`]:  moment(date).add(1,'days').toDate()}));   
        dt.start=moment(date).toDate();
        dt.end=moment(date).add(1,'days').toDate()
        SetWorkingHourDate((prev) => ({ ...prev, [`start`]:moment(date).set({"hour": sHour[0], "minute": sHour[1]})   }));
        SetWorkingHourDate((prev) => ({ ...prev, [`end`]:  moment(date).set({"hour": eHour[0], "minute": eHour[1],second:59 }) }));
        SetMaxDate(moment(date).add(1,'days').toDate())
      }
      else {
        // let message = `Work Day ${moment(date).subtract(1,'days').format('DD')} : ${moment(date).subtract(1,'days').format('DD MMM')} ${moment().set({hour:sHour[0]}).format("hA")} to ${moment(date).format('DD MMM')} ${moment().set({hour:eHour[0]}).format("hA")}`
        // SetNoteMessage(message);

        SetPastDay((prev) => ({ ...prev, [`start`]:moment(date).subtract(2,'days').toDate()}))
        SetPastDay((prev) => ({ ...prev, [`end`]:moment(date).subtract(2,'days').toDate()}))

        setDates((prev) => ({ ...prev, [`start`]:moment(date).subtract(1,'days').toDate()  }));
        setDates((prev) => ({ ...prev, [`end`]:  date.toDate()}));

        dt.start= moment(date).subtract(1,'days') ;
        dt.end=moment(date).toDate()


        SetWorkingHourDate((prev) => ({ ...prev, [`start`]:moment(date).subtract(1,'days').set({"hour": sHour[0], "minute": sHour[1]}) }));
        SetWorkingHourDate((prev) => ({ ...prev, [`end`]:  date.set( {"hour": eHour[0], "minute": eHour[1],second:59 } )  }));
        SetMaxDate(moment(date).toDate())
      }



      }
     
      
      





     })
      
     
    },[])


    const showModal = () => {
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    useEffect(() => {
    
      if(elementRef && elementRef.current){
        
        SetWidthContainer(elementRef.current.getBoundingClientRect().width);
      }
      
    }, [elementRef]);  
    const option =[
      {label:'demo 1',value:"demo 1"},
      {label:'demo 2',value:"demo 2"},
      {label:'demo 3',value:"demo 3"},
      {label:'demo 4',value:"demo 4"},
      {label:'demo 5',value:"demo 5"},
      {label:'demo 6',value:"demo 6"},
      {label:'demo 7',value:"demo 7"},
    ]
  
  

    useEffect(() => {

        if (props.match.path == '/') {
            props.history.push('/dashboard')
        }

        getOnBoardTag(userDetails.session,userDetails.org_id,sub_org_id).then(res=>{
          
          if(res.status==200){
            SetWorkerIDList(res.data.regular_worker_ids);
            SetDailyWorkerIDList(res.data.daily_worker_ids);
            SetDailyWorkerTag(res.data.daily_worker_tags);
            SetWorkerTag(res.data.regular_worker_tags);
            SetTagAPI(true);
          }
        })

        getfunctionList(userDetails.session,userDetails.org_id,sub_org_id).then(res=>{
          if(res.status==200){
            //SetRegularFunction(res.data)
            SetDailyFunction(res.data)
          }
        })
    }, []);

    function checkStatus (currentJakartaTime,localTime,agency="",user){
      const timeDifferenceMinutes = Math.abs(currentJakartaTime.diff(localTime, 'minutes'));

        if (timeDifferenceMinutes >= 840) {

          return "orange"
        }else if(timeDifferenceMinutes >= 480 &&( parseInt(userDetails.org_id)=== 33 || agency.toLowerCase() ==='lion parcel')){

          return "green"
        }
        else if(timeDifferenceMinutes >= 540 && timeDifferenceMinutes < 840 ){ 

          return "green"
        }else{

          return "red"
        }


      return ;
    }

    useEffect(()=>{
      let requestBody={};
      requestBody.startdate= moment(dates.start).format('YYYY-MM-DD');
      requestBody.enddate= moment(dates.end).format('YYYY-MM-DD');
      if(tagAPI){  


        
        getReportOnBoard(requestBody,userDetails.session,userDetails.org_id,sub_org_id).then(res=>{
          if(res.status==200){
            let checkInReg =  0
            let checkInDail =  0
            let ckOutDaily=     0
            let ckOutRg=     0

            let emp = []
            if(res.data && res.data.daily){
              //res.data.daily =res.data.daily.filter(item=>moment.utc(item.check_in) > moment(workingHourDate.start).set({"hour": 7, "minute": 0}) && moment.utc(item.check_in) < moment(workingHourDate.end).set({"hour": 6, "minute": 0}) )
             
             
              res.data.daily.map(item=>{
                let el=item;
                item.type="Daily Worker"
                if(dailyworkerIDList.find(item=>item.worker_id == item.worker_id)){
                   el.department= item.department_name;
                   el.function=item.sub_department_name   
                   el.check_in= moment.utc(item.check_in);
                   el.check_out=item.check_out==""||item.check_out==null?"-":moment.utc(item.check_out);
                   

                  if(el.check_out && el.check_out!="-"){

                      const localTime = moment(new Date(item.check_in._i));
                      const currentJakartaTime = moment(new Date(item.check_out._i));
                      el.status = checkStatus(localTime,currentJakartaTime,el.agency,el);
                  }else{
                    el.status = "-"
                  }


                }else{
                  el.department=item.department_name;
                  el.function=item.sub_department_name;   
                  el.check_in= moment.utc(item.check_in);
                  el.check_out=item.check_out==""||item.check_out==null?"-":moment.utc(item.check_out);
                  if(el.check_out && el.check_out!= "-"){
                    const localTime = moment(new Date(item.check_in._i));
                      const currentJakartaTime = moment(new Date(item.check_out._i));
                    el.status = checkStatus(localTime,currentJakartaTime,el.agency,el);
                  }else{
                    el.status = "-"
                  }
                }
                return el 
              })
              emp.push(...res.data.daily)
              checkInDail =  res.data.daily.filter(item=>item.check_in && item.check_out=='-').length;    
              ckOutDaily=     res.data.daily.filter(item=>item.check_in && item.check_out!='-').length;   
  
            }
            
            if(res.data && res.data.regular){  
              //res.data.regular =res.data.regular.filter(item=>moment.utc(item.check_in) > moment(dates.start).set({"hour": 7, "minute": 0}) && moment.utc(item.check_in) < moment(dates.end).set({"hour": 6, "minute": 0}) )
              res.data.regular.map(item=>{
                let el=item;
                item.type="Regular Worker"
                

                if(workerIDList.find(item=>item.worker_id == item.worker_id)){
                  el.check_in= moment.utc(item.check_in);
                    el.department= item.department_name;
                   el.function=item.sub_department_name
                  el.check_out=item.check_out==""||item.check_out==null?"-":moment.utc(item.check_out);

                  if(el.check_out && el.check_out!="-"){
                    const localTime = moment(new Date(item.check_in._i));
                    const currentJakartaTime = moment(new Date(item.check_out._i));
                    
                    el.status = checkStatus(localTime,currentJakartaTime,el.agency,el);
                  }else{
                    el.status = "-"
                  }


                }else{
                  el.department=item.department_name;
                  el.function=item.sub_department_name
                  el.check_in= moment.utc(item.check_in);
                  // el.check_out= "-";
                   el.check_out=item.check_out==""||item.check_out==null?"-":moment.utc(item.check_out);
                  
                   if(el.check_out && el.check_out!="-"){
                    const localTime = moment(new Date(item.check_in._i));
                    const currentJakartaTime = moment(new Date(item.check_out._i));
                    
                    el.status = checkStatus(localTime,currentJakartaTime,el.agency,el);
                  }else{
                    el.status = "-"
                  }

                   
                }
                return el 
              })
              ckOutRg=     res.data.regular.filter(item=>item.check_in && item.check_out!='-').length;   
              checkInReg =  res.data.regular.filter(item=>item.check_in && item.check_out=='-').length;            
  
              emp.push(...res.data.regular)
            }
           
            let checDataReg  =emp.filter(el=>el.type =="Regular Worker");
            //  "-"
            let regData = {
              scan_in:checDataReg.filter(el=>el.check_out=="-").length,
              scan_out:checDataReg.filter(el=>el.check_out!="-").length
             };

            let checDataDaily  =emp.filter(el=>el.type =="Daily Worker");
            //  "-"
            let dailyData = {
              scan_in:checDataDaily.filter(el=>el.check_out=="-").length,
              scan_out:checDataDaily.filter(el=>el.check_out!="-").length
            };

            SetDailyScanData(dailyData);
            SetRegularScanData(regData);

            SetCheckInDaily(checkInDail);
            SetCheckInRegular(checkInReg);
            SetCheckOut(ckOutRg+ckOutDaily);         
            SetOnBoardEmp(emp);
            SetOnboardCall(true);
            SetOnBoardEmpDisplay(emp);
          }
        })
      }
     },[dates,allemp,tagAPI,inc])


         useEffect(()=>{
          
      let requestBody={};
      requestBody.startdate= moment(pastDay.start).format('YYYY-MM-DD');
      requestBody.enddate= moment(pastDay.end).format('YYYY-MM-DD');

      if(tagAPI){  



        
        getReportOnBoard(requestBody,userDetails.session,userDetails.org_id,sub_org_id).then(res=>{
          if(res.status==200){

            let emp = []
            if(res.data.daily){
              res.data.daily = res.data.daily.filter(el=>el.check_out ===null)
            }

            if(res.data.regular){
              res.data.regular = res.data.regular.filter(el=>el.check_out ===null)
            }
            
            if(res.data && res.data.daily){
              //res.data.daily =res.data.daily.filter(item=>moment.utc(item.check_in) > moment(workingHourDate.start).set({"hour": 7, "minute": 0}) && moment.utc(item.check_in) < moment(workingHourDate.end).set({"hour": 6, "minute": 0}) )             
             
              res.data.daily.map(item=>{
                let el=item;
                item.type="Daily Worker"
                if(dailyworkerIDList.find(item=>item.worker_id == item.worker_id)){
                   el.department= item.department_name;
                   el.function=item.sub_department_name   
                   el.check_in= moment.utc(item.check_in);
                   el.check_out=item.check_out==""||item.check_out==null?"-":moment.utc(item.check_out);
                }else{
                  el.department=item.department_name;
                  el.function=item.sub_department_name;   
                  el.check_in= moment.utc(item.check_in);
                  el.check_out=item.check_out==""||item.check_out==null?"-":moment.utc(item.check_out);
                 
                }
                return el 
              })
              emp.push(...res.data.daily)
  
            }
            
            if(res.data&&res.data.regular){  
              //res.data.regular =res.data.regular.filter(item=>moment.utc(item.check_in) > moment(dates.start).set({"hour": 7, "minute": 0}) && moment.utc(item.check_in) < moment(dates.end).set({"hour": 6, "minute": 0}) )
              res.data.regular.map(item=>{
                let el=item;
                item.type="Regular Worker"
                
                if(workerIDList.find(item=>item.worker_id == item.worker_id)){
                  el.check_in= moment.utc(item.check_in);
                  el.department= item.department_name;
                  el.function=item.sub_department_name
                  el.check_out=item.check_out==""||item.check_out==null?"-":moment.utc(item.check_out);
                }else{
                  el.department=item.department_name;
                  el.function=item.sub_department_name
                  el.check_in= moment.utc(item.check_in);
                  el.check_out=item.check_out==""||item.check_out==null?"-":moment.utc(item.check_out);
                }
                return el 
              })
  
              emp.push(...res.data.regular)
            }

            SetNonCheckout(emp)
          }
        })
      }
     },[pastDay,allemp,tagAPI,inc])


     function resumeScan(){
        // SetScanEnable(true);
     }

     function holdScan(){
      //  SetScanEnable(false);
     }


     useEffect(()=>{
       
      let checkInUser = onBoardEmp.find(item=>(item.worker_id == selectedEmp.worker_id || item.tag_serial== selectedEmp.tag_serial)  && new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) &&  ( item.check_out!='') );
 


 if(checkInUser && selectedEmp.worker_id && selectedEmp.tag_serial){
    Addhandler(1);
    
 }
 else if(selectedEmp.worker_id && selectedEmp.tag_serial && selectedEmp.worker_type  =="regular"){
       
        // setShowFunctionPicker(false);
        Addhandler(2);
        resumeScan();
    
        
    }else if(selectedEmp.worker_id && selectedEmp.tag_serial && selectedEmp.worker_type  =="daily" && checkInUser){
        Addhandler(3);
    
        // setShowFunctionPicker(false);
    }else if(selectedEmp.worker_id && selectedEmp.tag_serial && selectedEmp.worker_type  =="daily" && Function==""){
     
        // setShowFunctionPicker(true);
        holdScan();
    }else if(selectedEmp.worker_id && selectedEmp.tag_serial && selectedEmp.worker_type  =="daily" && Function!==""){
     
        // setShowFunctionPicker(false);
        Addhandler(4);
        // resumeScan();
    }

     },[selectedEmp,Function,onBoardEmp,workingHourDate])





     useEffect(()=>{
      
      if(dates.start!=null && dates.end!=null){
      let requestBody={};
      requestBody.startdate= moment(dates.start).format('YYYY-MM-DD');
      requestBody.enddate= moment(dates.end).format('YYYY-MM-DD');
      requestBody.slug= 'employee';
      let arr=[]
      getProductivitylist(requestBody,userDetails.session,userDetails.org_id,sub_org_id).then(res=>{
        if(res.data && res.status==200){
          let catIndex=0;
          let empList= [];
        
           SetAllEmp(res.data);             
           

          }
          if(res=="Session expired"){
            SetModalLogout(true);          
          }
      })
    }
     },[dates])
    const handleDate = (date, type) => {
      setDates((prev) => ({ ...prev, [`${type}`]: date }));
      let startDate = dates.start
      let endDate = dates.end
      if(type=="start"){
        startDate = date
        let message = `${translate('Work Day',language)} ${moment(startDate).format('DD')} : ${moment(startDate).format('DD MMM')} 7AM to ${moment(endDate).format('DD MMM')} 6AM`
        //SetNoteMessage(message)
      }else{
        endDate = date
        let message = `${translate('Work Day',language)} ${moment(startDate).format('DD')} : ${moment(startDate).format('DD MMM')} 7AM to ${moment(endDate).format('DD MMM')} 6AM`
        //SetNoteMessage(message)
      }

    };
    function isInt(value) {
      return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
    }


useEffect(() => {
    if(search && search.length > 0){
        searchHandler(search,workingHourDate);
        console.log("Search Handler Hit again",search);
    }    
}, [search,workingHourDate])



function searchHandler(val,workingHourDate){
  val = val.trim();
  
  
  if(workingHourDate.start && workingHourDate.end && val.length > 0){
  playSound("any value "+val);  
  let emp= selectedEmp?selectedEmp:{};
  let res= false;

  if(val.length>0){
    let checkTag ='';
    inProcessRef.current = true;  
    if(val.startsWith("01")){
      checkTag =  val.includes(":")?val.replaceAll(":", "").toUpperCase():val.toUpperCase() ;
    }else{
      checkTag =  val.includes(":")? "01"+val.replaceAll(":", "").toUpperCase():"01"+val.toUpperCase() ;
    }



  if(workerIDList.find(item => item.worker_id ==  val.replaceAll(":", ""))){

    let wk = workerIDList.find(item => item.worker_id ==  val.replace(":", ""))
    // let checkCond = [...onBoardEmp].find(item=>item.worker_id== val.replace(":", "")  && item.check_in!=null && (item.check_out!=null && item.check_out!='-')  && (item.check_in > workingHourDate.start && item.check_out < workingHourDate.end)  )
    let checkCond = [...onBoardEmp].find(item=>item.worker_id== val.replace(":", "")  && item.check_in!=null && (item.check_out!=null && item.check_out!='-')  && (new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`)  > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) && new Date(`${moment(item.check_out).format('YYYY-MM-DD HH:mm')}`)  < new Date(`${moment(workingHourDate.end).format('YYYY-MM-DD HH:mm')}`))  )
    
    SetSearch('');
    if(checkCond){

      toast.error( "TP:07 "+translate("Invalid :- Tag used for the day",language));
      playSound("Invalid :- Tag used for the day");
    }else if(emp.tag_serial && dailyworkerTag.indexOf(emp.tag_serial) > -1 ){
      playSound("Scanned tag not belong to regular worker");
      toast.error("TP:08 +"+translate("Scanned tag not belong to regular worker",language))
    }else{
  
    let rgTag= [...allemp].find(item=>item.worker_id == val.replace(":", ""))
    if(rgTag){      
      let emp={}
      emp.type='Regular Worker'
      emp.worker_type= 'regular'      
      emp.worker_id= wk.worker_id
      emp.tag_serial= rgTag.tag_serial
      emp.department= wk.department
      emp.agency= wk.agency?wk.agency:""
      emp.name= wk.name

      SetWorkerID(wk.worker_id);
      SetTagID(rgTag.tag_serial);
      SetSelectedEmp(emp);
      if(isModalOpen){      setIsModalOpen(false);SetModalSearch("")}

      toast.success('TP:09 '+translate('Valid Regular Worker ID',language))
        playSound("Valid Regular Worker ID");
    }
    
  
    res=true;
    
  }


  }

  else if(workerTag.indexOf(checkTag.replaceAll(":", ""))> -1){
    SetSearch('')
    res=true;
    let tagSelect= workerTag.indexOf(checkTag.replaceAll(":", "").toUpperCase())

    let rgTag= [...allemp].find(item=>item.tag_serial == workerTag[tagSelect])
    let checkCond = [...onBoardEmp].find(item=>item.tag_serial== workerTag[tagSelect]  && item.check_in!=null && (item.check_out!=null && item.check_out!='-') &&       ( new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) && new Date(`${moment(item.check_out).format('YYYY-MM-DD HH:mm')}`) < new Date(`${moment(workingHourDate.end).format('YYYY-MM-DD HH:mm')}`) ))

    SetSearch('');
    if(checkCond){
      toast.error(translate("TP:10 Invalid :- Tag used for the day",language));
    }else{
      if(rgTag){
        SetTagID(workerTag[tagSelect])
        SetWorkerID(rgTag.worker_id)
        let emp={}
        emp.type='Regular Worker'
        emp.worker_type= 'regular'
        let agency =workerIDList.find(e=>e.worker_id== checkTag.replaceAll(":", "") )?.agency

        emp.worker_id= rgTag.worker_id
        emp.tag_serial= rgTag.tag_serial
        emp.department= rgTag.department
        emp.name= rgTag.name
        emp.agency=workerIDList.find(e=>e.worker_id == rgTag.worker_id)?.agency
        emp.tag_serial=workerTag[tagSelect];
        emp.worker_id= rgTag.worker_id

        
        SetSelectedEmp(emp);      
  
        toast.success('TP:11 '+translate('Valid Regular Worker Tag ID',language));
  
        if(isModalOpen){  
              setIsModalOpen(false)
          }
  
          if(isModalOpen){      setIsModalOpen(false);SetModalSearch("")}  

      }else if(emp.worker_id &&   dailyworkerIDList.findIndex(item=>item.worker_id == emp.worker_id)  > -1){
        toast.error("TP:12 "+translate("Scanned tag not belong to regular worker",language))
        
      }
            
    }

    


  }
    //daily Worker Id Regular
  else if(dailyworkerIDList.find(item=> item.worker_id == val.replaceAll(":", ""))){
    let checkCond = [...onBoardEmp].find(
      item=>item.worker_id ==  val.replace(":", "")  &&
      item.check_in!=null && new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) && (item.check_out!=null &&
      item.check_out!='-' 
    ))

    let checkCheckinData= [...onBoardEmp].find(
        item=>item.worker_id ==  val.replace(":", "")  && 
        item.check_in!=null &&
        (item.check_out!=null && 
        (item.check_out==null || item.check_out=='-' ) && (new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) && new Date(`${moment(item.check_out).format('YYYY-MM-DD HH:mm')}`) < new Date(`${moment(workingHourDate.end).format('YYYY-MM-DD HH:mm')}`)) )  
        );       
    SetSearch('');
    if(checkCond){
      playSound("Invalid :- Tag used for the day");
      toast.error("TP:13 "+translate("Invalid :- Tag used for the day",language));

    }
    else if(checkCheckinData){
      
      //mapping Daily worker
        let emp={}
        emp.type='Daily Worker'
        emp.worker_type= 'daily'
        emp.worker_id= checkCheckinData.worker_id
        emp.tag_serial= checkCheckinData.tag_serial
        emp.department= checkCheckinData.department?checkCheckinData.department:"-"
        emp.name= checkCheckinData.name  
        emp.agency = dailyworkerIDList.find(item=> item.worker_id == val.replaceAll(":", ""))?.agency
        SetSelectedEmp(emp);     
        SetTagID(checkCheckinData.tag_serial) 
        SetWorkerID(checkCheckinData.worker_id) 
        toast.success('TP:20 '+translate('Valid Daily Worker ID',language));
              playSound("Valid Daily Worker ID");
        if(isModalOpen){      setIsModalOpen(false);SetModalSearch("")}
    }
    else if(emp.tag_serial  &&  workerTag.indexOf(emp.tag_serial)>  -1){
                    playSound("Invaild ID scanned, Please clear and scan again");
      toast.error("TP:14 "+translate("Invaild ID scanned, Please clear and scan again",language))
    }else{
      emp.type='Daily Worker'
      emp.worker_type= 'daily'
      let wk =dailyworkerIDList.find(item => item.worker_id ==  val.replace(":", ""))
      emp.worker_id= wk.worker_id
      emp.department= wk.department
      emp.name= wk.name
      emp.agency = wk.agency?wk.agency:""
      let funcDetail =  dailyfunction.find(el=>el.category_name === wk.department)
      if(wk.department_id){
        SetFunction(parseInt(wk.department_id));
      }
      let tagS = onBoardEmp.find(item=>item.worker_id == val.replace(":", "") && new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) );
     
      if(tagS){
        emp.tag_serial= tagS.tag_serial;
        SetTagID(tagS.tag_serial);
      }
      SetWorkerID(wk.worker_id)
      SetSearch('')
      SetSelectedEmp(emp);
      if(isModalOpen){      setIsModalOpen(false);SetModalSearch("")}
         toast.success('TP:15 '+translate('Valid Daily Worker ID',language))
        playSound("Valid Daily Worker ID");
    }
    res=true;
  }
    //daily tag serial
  else if(dailyworkerTag.indexOf(checkTag.replaceAll(":", ""))> -1){
    let tagSelect= dailyworkerTag.indexOf(checkTag.replaceAll(":", ""))
    SetSearch('')
    let checkCond = [...onBoardEmp].find(item=>item.tag_serial ==  dailyworkerTag[tagSelect]  && item.check_in!=null && (item.check_out!=null && item.check_out!='-') &&  (new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) && new Date(`${moment(item.check_out).format('YYYY-MM-DD HH:mm')}`) < new Date(`${moment(workingHourDate.end).format('YYYY-MM-DD HH:mm')}`)))
    let checkCheckinData = [...onBoardEmp].find(item=>item.tag_serial ==  dailyworkerTag[tagSelect]  && item.check_in!=null && new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) && (item.check_out==null || item.check_out=='-')  )
    SetSearch('');
    if(checkCond){
      toast.error("TP:16 "+translate("Invalid :- Tag used for the day",language));
      playSound("Invalid :- Tag used for the day");
    }else if(checkCheckinData){
      let emp={}
        emp.type='Daily Worker'
        emp.worker_type= 'daily'
        emp.worker_id= checkCheckinData.worker_id
        emp.tag_serial= checkCheckinData.tag_serial
        emp.department= checkCheckinData.department?checkCheckinData.department:"-"
        emp.name= checkCheckinData.name 
        emp.agency = dailyworkerIDList.find(item=> item.worker_id == checkCheckinData.worker_id)?.agency  
        SetSelectedEmp(emp);      
        SetTagID(checkCheckinData.tag_serial) 
        SetWorkerID(checkCheckinData.worker_id)
        toast.success('TP:21 '+translate('Valid Daily Worker ID',language));
         playSound("Valid Daily Worker ID");
        if(isModalOpen){      setIsModalOpen(false);SetModalSearch("")}

    }

    else if(emp.worker_id && workerIDList.findIndex(item=> item.worker_id == emp.worker_id) > -1){
      toast.error("TP:17 "+translate("Scanned tag not belong to daily worker",language))
      playSound("Scanned tag not belong to daily worker");

    }else{
      emp.tag_serial=dailyworkerTag[tagSelect]
      emp.type='Daily Worker'
      emp.worker_type= 'daily'

      let tagS = onBoardEmp.find(item=>item.tag_serial == checkTag.replaceAll(":", "") && new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`));
     
      if(tagS){

        SetWorkerID(tagS.worker_id);
        emp.name=tagS.name
        emp.department=tagS.department
      }
      SetSelectedEmp(emp);
      SetTagID(dailyworkerTag[tagSelect])
      if(isModalOpen){      setIsModalOpen(false);SetModalSearch("")}
      toast.success('TP:18 '+translate('Valid Daily Worker Tag ID'))
      playSound("Valid Daily Worker Tag ID");      
    }
    res=true;
  }else{
    toast.error('TP:19 '+translate('Invalid',language)+' - '+val);
    playSound("Invalid");
    SetSearch('')
    res=true;
  }
  SetMessageDisplay(true)

}
}else{
  console.log("else console",val,workingHourDate);
}
}


useEffect(()=>{
  
  if(!moment(workingHourDate.end).isValid()){
    
    return 
  }
   const targetDate = new Date(`${moment(workingHourDate.end).format('YYYY-MM-DD HH:mm:ss')}`);
  //  const targetDate = new Date(`2024-07-11 20:42:53`);
    
    const checkTime = () => {
      const now = new Date();

      if (now >= targetDate) {
        
       window.location.reload();

      }
    };

    const intervalId = setInterval(checkTime, 20000);

    return () => clearInterval(intervalId);
                
    
},[workingHourDate])





    const colorPicker = (percent)=>{
      if(percent<25.1){
        return '#28a745'
      }
      else if(percent<50.1){
        return '#e8ad41'
      }else if(percent<75.1){
        return '#dc3545'
      }else{
        return 'purple'
      }
       
    }



    function checkoutForce(){
      let emp = {...selectedEmp};
      holdScan();

            emp.function=Function;
      emp.check_in=moment();
      emp.function= dailyfunction.find(item=>item.category_id == Function )?.category_name
    
      
      let requestBody = {}          
      requestBody.session = userDetails.session
      requestBody.worker_id = workerID
      requestBody.tag_serial = tagID
      requestBody.category_id = Function
      requestBody.org_id = userDetails.org_id
      requestBody.sub_org_id = userDetails.sub_org_id
    
      SetAPIEnable(false);

     postCreadonBoard(requestBody).then(res=>{
      //SetAPIEnable(true);
      setTimeout(function(){
        resumeScan();
        SetAPIEnable(true);
      },2000)
      if(res.status==200){
        SetShiftShortModal(false);
        if(res.data=="Checkout"){
          playSound('checkoutSuccess');
          toast.success("TP:04 "+translate("Check-Out Sucess",language));  

          let allCheckOut = checkOut+1
            SetCheckOut(allCheckOut) 
            
            
            if(emp.type=='Regular Worker'){
              let regularCheckin=checkInRegular>0?checkInRegular - 1:0;        
              SetCheckInRegular(regularCheckin) 

            }else{
              let dailyCheckin=checkInDaily>0?checkInDaily - 1:0;        
              SetCheckInDaily(dailyCheckin) 

            }

        }else if(res.data=="Checkin"){
          toast.success("TP:05 "+translate("Check-In Sucess",language));          
                    playSound('checkinSuccess');
          if(emp.type=='Regular Worker'){
                  let regularCheckin= checkInRegular + 1;     
                  SetCheckInRegular(regularCheckin) 
      
                }else{
                  let dailyCheckin=checkInDaily + 1;        
                  SetCheckInDaily(dailyCheckin) 
      
                }
        }
        else{
          playSound('failed');
          toast.error("TP:06 "+translate("Invalid :- Tag used for the day",language))
        }

  
          SetMessageErrorTag("")
          SetMessageErrorWorker('')
          SetMessageErrorFunction("")
      
          SetMessageDisplay(true);
          setTimeout(function(){
            SetMessageDisplay(false);
          },1000)
          SetWorkerID('');
          SetSearch('');
          SetTagID('');
          SetFunction('');
          SetSelectedEmp({})
    
          myFocusFunction('','')

          SetInc((prev)=>prev +1);
      }else if (res.status==302){
                  playSound('failed');
        Modal.error({
          title: translate('Fail to Onboard',language),
          content: translate('Something Went Wrong, Please try again',language),
        });

      }else{
                  playSound('failed');
        Modal.error({
          title: translate('Fail to Onboard',language),
          content: translate(res.message,language)?translate(res.message,language):translate('Something Went Wrong',language),
        });
      }
     }).catch(err=>{
      setTimeout(function(){
        SetAPIEnable(true);
        resumeScan();
      },2000)

     })

    }
    

function Addhandler(){
 
  if(selectedEmp){
    if(tagID==''){
      //SetMessageErrorWorker("Trace+ ID is required")
      toast.error("TP:01 "+translate("Trace+ ID is required",language));
    }else if(workerID==''){
      SetMessageErrorWorker("")
      //SetMessageErrorTag("Worker ID is required")
      toast.error("TP:02 "+translate("SWorker ID is required",language));
    }else if(Function=='' && selectedEmp=="Daily Worker"){
      SetMessageErrorTag("")
      toast.error("TP:03 "+translate("Function is required",language));
      // SetMessageErrorFunction("Function is required");
    }else{
      holdScan();
      let emp = {...selectedEmp};
      

      let allempBoard=[...onBoardEmp];

      let checkInUser = onBoardEmp.find(item=>(item.worker_id == selectedEmp.worker_id || item.tag_serial== selectedEmp.tag_serial)  && new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) &&  ( item.check_out!='') );
      let checkoutProper = true;
    
      let condition="check_in";

      if(checkInUser){

      condition="check_out"; 

      const localTime = moment(new Date(checkInUser.check_in._i));
      const currentJakartaTime = moment(new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Jakarta' })));


      const timeDifferenceMinutes = Math.abs(currentJakartaTime.diff(localTime, 'minutes'));
      const hours = Math.floor((540 - timeDifferenceMinutes) / 60);
      const minutes = Math.floor((540 - timeDifferenceMinutes) % 60);

      const formattedDifference = `${String(hours).padStart(2, '0')} hr:${String(minutes).padStart(2, '0')} min`;

        if (timeDifferenceMinutes > 540) {

        } else {
          checkoutProper =false;
          SetShortTime(formattedDifference);
         // SetScanEnable(false);
          
        //enable after test
        //  SetShiftShortModal(true);

          playSound('earlyCheckout')
        }
      }

      
      if(!checkoutProper){
      
      }
      else{

      emp.function=Function;
      emp.check_in=moment();
      emp.function= dailyfunction.find(item=>item.category_id == Function )?.category_name
    
      
      let requestBody = {}          
      requestBody.session = userDetails.session
      requestBody.worker_id = workerID
      requestBody.tag_serial = tagID
      requestBody.category_id = Function
      requestBody.org_id = userDetails.org_id
      requestBody.sub_org_id = userDetails.sub_org_id
    
      SetAPIEnable(false);
      


     postCreadonBoard(requestBody).then(res=>{
      //SetAPIEnable(true);
      
      setTimeout(function(){
        SetAPIEnable(true);
        resumeScan();
      },2000)
      if(res.status==200){
        
        if(res.data=="Checkout"){
          toast.success("TP:04 "+translate("Check-Out Sucess",language));  
          playSound('checkoutSuccess')
          let allCheckOut = checkOut+1
            SetCheckOut(allCheckOut) 
            
            
            if(emp.type=='Regular Worker'){
              let regularCheckin=checkInRegular>0?checkInRegular - 1:0;        
              SetCheckInRegular(regularCheckin) 

            }else{
              let dailyCheckin=checkInDaily>0?checkInDaily - 1:0;        
              SetCheckInDaily(dailyCheckin) 

            }

        }else if(res.data=="Checkin"){
          toast.success("TP:05 "+translate("Check-In Sucess",language)); 
          playSound('checkinSuccess')         
          if(emp.type=='Regular Worker'){
                  let regularCheckin= checkInRegular + 1;     
                  SetCheckInRegular(regularCheckin) 
      
                }else{
                  let dailyCheckin=checkInDaily + 1;        
                  SetCheckInDaily(dailyCheckin) 
      
                }
        }
        else{
          playSound('failed')         
          toast.error("TP:06 "+translate("Invalid :- Tag used for the day",language))
        }

  
          SetMessageErrorTag("")
          SetMessageErrorWorker('')
          SetMessageErrorFunction("")
      
          SetMessageDisplay(true);
          setTimeout(function(){
            SetMessageDisplay(false);
          },1000)
          SetWorkerID('');
          SetSearch('');
          SetTagID('');
          SetFunction('');
          SetSelectedEmp({})
    
          myFocusFunction('','')

          SetInc((prev)=>prev +1);
      }else if (res.status==302){

        Modal.error({
          title: translate('Fail to Onboard',language),
          content: translate('Something Went Wrong, Please try again',language),
        });
        playSound('failed');

      }else{
        Modal.error({
          title: translate('Fail to Onboard'),
          content: translate(res.message?res.message:'Something Went Wrong',language),
        });
        playSound('failed');
      }
     }).catch(err=>{
      playSound('failed');
      setTimeout(function(){
        SetAPIEnable(true);
      },2000)

     })

 
     
      }
      
    
    }
  }

}

function AGGridEMptyMEssage(key){
  let msg;
      msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
  return msg
}


const onCellClicked = (params) =>{

      if (window.getSelection) {
          SetFocus(false);
          
      }else{
 setTimeout(function(){
    SetFocus(true);
  },3000)

      }
} 

function newRanderer(value,i){
  const eGui = document.createElement('div');
  let imgSrc;
  let alt="";

  switch (value) {
    case "green":
      imgSrc = GreenFlag;
      alt="Complied"
      break;
    case "orange":
      imgSrc = YellowFlag;
      alt="Non checkout "
      break;
    case "red":
      imgSrc = RedFlag;
      alt="Short time"
      break;
    default:
      imgSrc = ''; // Or some default image or empty string
  }
  if(imgSrc){
    eGui.innerHTML = `<img src="${imgSrc}" alt="${alt}" title="${alt}" width="20px" />`;
  }
  
  return eGui;
}

function myFocusFunction(wid,tid){
 
 
}

function ClearHandhandler(){
  SetMessageErrorTag("")
  SetMessageErrorWorker('')
  SetMessageErrorFunction("")
  SetWorkerID('');
  SetSearch('');
  SetTagID('');
  SetFunction('');
  SetSelectedEmp({})
  myFocusFunction('','')
}

function focusOff(){
 SetFocus(false); 
}
function focusTrue(){
 SetFocus(true); 
}
function languageChangeHandler(val){

  localStorage.setItem('userOnBoardLanguage', val);
  SetLanguage(val);
  focusTrue();
}

function languageChangeBlur(){
  focusTrue();
}

useEffect(() => {
  const delayDebounceFn = setTimeout(() => {
    searchHandler(search,workingHourDate)

  }, 600)

  return () => clearTimeout(delayDebounceFn)
}, [search,workingHourDate])


function modalSearchHandler(workingHourDate){
  if(modalSearch.length>0){
    //SetSearch(modalSearch);
    searchHandler(modalSearch,workingHourDate)
    //setIsModalOpen(false);
  }
}

function modalLogoutHandler(){
 
  SetModalLogout(false);
  localStorage.removeItem('userLoginDetailsOnBoard')
  localStorage.removeItem('isLoggedInGatewayOnBoard');
  localStorage.removeItem('tokenAuthTraceOnBoard');
  props.history.push('/login-mobile') ;       
  
  }

  useEffect(()=>{
    if(onBoardEmp.length> 0){
      let filter = [...onBoardEmp].filter(item=>
          item.name.toLowerCase().includes(searchCheckIn.toLowerCase().trim()) ||
  
          
          item.worker_id.includes(searchCheckIn.replaceAll(":", "")) 
          ||
          item.tag_serial.toLowerCase().includes(searchCheckIn.toLowerCase().replaceAll(":","")) 
           )

           SetOnBoardEmpDisplay(filter);
    }
  },[searchCheckIn])  

//   function playSound(type){
//      if (window.speechSynthesis) {
//         window.speechSynthesis.cancel();
//        
//     }
    
//     if(type==="checkinSuccess" && checkInSucessRef.current){
//         checkInSucessRef.current.play();
//        
//     }else if(type==="checkinFail" && checkInFailRef.current){
//       checkInFailRef.current.play();

//     }else if(type==="checkoutSuccess" && checkOutFailRef.current){
//       checkOutFailRef.current.play();
//     }else if(type==="checkoutFail" && checkOutFailRef.current){
//       checkOutFailRef.current.play()
//              
//     }else if( type === "earlyCheckout" && earlyCheckoutRef.current){
//       earlyCheckoutRef.current.play();
//              
//     }else if(type==="validTagSerial" && validTagSerialRef.current){
//       validWorkerIDRef.current.play()
//      
//     }else if(type==="validWorkerID" && validWorkerIDRef.current){
//       validWorkerIDRef.current.play()
//        
//     }else if(type==="failed" && failedRef.current){
//       failedRef.current.play()
//     }else if(type==="success" && successRef.current){
//       successRef.current.play();
//            
//     }else if(type.toLowerCase().includes('invalid')){
//              
//       if(window.speechSynthesis){
//           const synth = window.speechSynthesis;
//           const u = new SpeechSynthesisUtterance("Invalid");
//           synth.speak(u);
//       }
//     }else{
      
//       if(window.speechSynthesis){
//           const synth = window.speechSynthesis;
//           const u = new SpeechSynthesisUtterance(type);
//           synth.speak(u);
//       }
//     }

//   }

function playSound(type) {
    if (window.speechSynthesis && inProcessRef.current) {
        window.speechSynthesis.cancel();
    }
    let audioElement = null;
    if (type === "checkinSuccess" && checkInSucessRef.current) {
        audioElement = checkInSucessRef.current;

    } else if (type === "checkinFail" && checkInFailRef.current) {
        audioElement = checkInFailRef.current;
    } else if (type === "checkoutSuccess" && checkOutFailRef.current) {
        audioElement = checkOutFailRef.current;
    } else if (type === "checkoutFail" && checkOutFailRef.current) {
        audioElement = checkOutFailRef.current;
    } else if (type === "earlyCheckout" && earlyCheckoutRef.current) {
        audioElement = earlyCheckoutRef.current;
    } else if (type === "validTagSerial" && validTagSerialRef.current) {
        audioElement = validTagSerialRef.current;
    } else if (type === "validWorkerID" && validWorkerIDRef.current) {
        audioElement = validWorkerIDRef.current;
    } else if (type === "failed" && failedRef.current) {
        audioElement = failedRef.current;
    } else if (type === "success" && successRef.current) {
        audioElement = successRef.current;
    } else if (type.toLowerCase().includes('invalid')) {
        if (window.speechSynthesis) {
            const synth = window.speechSynthesis;
            const u = new SpeechSynthesisUtterance("Invalid");
            console.log("synth get voice",synth.getVoices());
            synth.speak(u);
            u.onend = () => {
                inProcessRef.current = false;
                // detectFace();
                toast.dismiss();
                console.log("Process false mean free 1" )
            };
            return;
        }
    } else {
      console.log("is here sound");
        if (window.speechSynthesis) {
            const synth = window.speechSynthesis;
            const u = new SpeechSynthesisUtterance(type);
            console.log("synth get voice",synth.getVoices());
            synth.speak(u);
            u.onend = () => {
              console.log("proccess free ");
                inProcessRef.current = false;
                //  detectFace();
                toast.dismiss();
                                console.log("Process false mean free 2" )
            };
            return;
        }else{
          // console.log("sound is not played for valid tag and process stuck" );
          //                 inProcessRef.current = false;
        }
    }

    if (audioElement) {
        audioElement.play();
        // inProcessRef.current = true;
        audioElement.onended = () => {
            inProcessRef.current = false;
            toast.dismiss();
            //  detectFace();
            console.log("Process false mean free 3" )
        };
    }
}



 let isLoggedInGateway = JSON.parse(localStorage.getItem('isLoggedInGatewayOnBoard'))

    if (!isLoggedInGateway) {
        return (
            <div>
                {props.history.push('/login-mobile')}
            </div>
        )
    }else{

    return (

         <div className="main-content main-contentMobile  side-content pt-0">
    <ToastContainer
      autoClose={800}
      newestOnTop={false}
      closeOnClick
      pauseOnFocusLoss={false}
      pauseOnHover={false}
      width={"500px"}


    />
        {!firstClick?  <div className='firstClickArea' style={{backgroundColor:"rgb(255,255,255,.9)"}} onClick={()=>SetFirstClick(true)}>
      <div className='onboardInnerStart'>
        <img src={TraceLogo} />
        <h2>Welcome to onboard</h2>
        <p>Click anywhere to start</p>
        </div>
      </div>:""}

<div className="container-fluid">
    <div className="inner-body">






    <CommonHeading title={<span>{translate('Welcome',language)} <strong>{userDetails.empName}</strong></span>} MainTitle={translate("Worker Onboarding",language)} language={language} focusOff={focusOff} languageChangeHandler={languageChangeHandler} focusTrue={focusTrue} languageChangeBlur={languageChangeBlur} />


            <Modal
          title={
            <div className='modalHeaderCustom'>
              <img src={WarningSign} className='warning-img'/>
              <h4 style={{color:"#e76173",fontWeight: "bold"}}>{translate('Warning',language)}</h4>
            </div>
            
            }
          open={shiftShortModal}
          okText={translate("Yes, Check-Out",language)}
          cancelText={translate("No, Go Back",language)}
          onOk={checkoutForce}
          onCancel={()=>{SetShiftShortModal(false);SetShortTime("");ClearHandhandler();    
            // SetScanEnable(true) ;      
          }}
         
          className="logoutModal logoutModalHieght2" 
          //className="modalStyle"
          // cancelButtonProps={{ disabled: true }}
        >
          <div className='formField formFieldUnderModal'>
           <p className='shiftShort'>{shortTime}<small>{translate('Short Time',language)}</small></p>
           <p><strong>English </strong>: "You're trying to check out before your shift ended. Are you sure you want to check out?"</p> 
           <p><strong>Bahasa </strong>: "Anda mencoba melakukan check out sebelum jam kerja anda selesai. Apakah anda yakin ingin melakukan check out? "</p>
    
        </div>
        </Modal>

<Modal
          title={translate("Edit QRCode/ Barcode",language)}
          open={isModalOpen}
          okText={translate("Submit",language)}
          cancelText={translate("Close",language)}
          onOk={()=>modalSearchHandler(workingHourDate)}
          onCancel={()=>setIsModalOpen(false)}
          okButtonProps={{ disabled: modalSearch.length==0 }}
          // cancelButtonProps={{ disabled: true }}
        >
          <div className='formField formFieldUnderModal'>
            <label>{translate("Please Enter Barcode or QRCode (MAC ID) Serial",language)}</label>
          <input type="text" className='form-control' value={modalSearch}  onChange={(e)=>SetModalSearch(e.target.value)} />
        </div>
        </Modal>
    
      <DashboardStats totalTagDaily={[...onBoardEmp].filter(item=>item.type=="Daily Worker").length} regularTagCheckIN={[...onBoardEmp].filter(item=>item.type=="Regular Worker" ).length} checkout={checkOut} dailyData={dailyScanData} regularData={regularScanData} language={language} />
    
    <div className='onBoardScanArea'>
      <div className='onBoardScanInner'>
        
        <Row className='topAreaScan'>
          <Col lg={3}></Col>
        <Col lg={6}>  
        
        <div className='form-field form-field2'>
        
        <label>{translate('Scan',language)} </label>
        <div className='threething'>
          <input type="text" name="server"
            className='form-control'
            value={search}
            onChange={(e)=>SetSearch(e.target.value)}   
            id="idOfInput" autoFocus={true}
            disabled={tagID!="" && workerID!=""}
            readOnly={true}
            placeholder={tagID!="" && workerID!=""?translate("Please CheckIn/Checkout or Clear before new scan",language):""}
            />
          <p className='btn btn-clear' style={{float:'left',color:'#ef5e8c',marginLeft:"10px",marginTop:"25px",fontWeight:"bold"}} onClick={showModal}><Edit/></p>
          <p className='btn btn-clear' style={{float:'left',color:'#ef5e8c',marginLeft:"10px",marginTop:"25px",fontWeight:"bold"}} onClick={ClearHandhandler}><Clear/></p>
        </div>
          
          
       </div>
       </Col>
       <Col lg={3}></Col>
        </Row>  
        <Row className="topAreaScan topAreaScan2" style={{paddingBottom:10,marginTop:0}}>
          <Col xs={12}
          //  style={{paddingRight:"5px"}}
           >
          <div style={{backgroundColor:"#000",position:"relative",minHeight:300}}>

          {workingHourDate.start!=null && workingHourDate.end!=null && tagAPI && onBoardCall && scanEnable && apiEnable && 
          checkInSucessRef.current &&  checkInFailRef.current && checkOutFailRef.current && checkOutSucessRef.current && earlyCheckoutRef.current && successRef.current && failedRef.current &&
          firstClick
          ?
          <>
          <Scanner 
              onScan={(result) =>{if(result[0] && result[0].rawValue) SetSearch(result[0].rawValue)} }
              allowMultiple={true}
              scanDelay={1000}
              container={{width:"90px",maxWidth:"120px", height:"120px"}}
              styles={{container:{width:window.innerWidth - 50,height:340}}}
              formats={[
                    'qr_code',
                    'micro_qr_code',
                    'rm_qr_code',
                    'maxi_code',
                    'pdf417',
                    'aztec',
                    'data_matrix',
                    'matrix_codes',
                    'dx_film_edge',
                    'databar',
                    'databar_expanded',
                    'codabar',
                    'code_39',
                    'code_93',
                    'code_128',
                    'ean_8',
                    'ean_13',
                    'itf',
                    'linear_codes',
                    'upc_a',
                    'upc_e'
                ]}
                height={300}
               
              
                // styles={{width:"100%",height:300}}
                components={{
                    audio: false,
                    onOff: false,
                    torch: false,
                    zoom: false,
                    finder: true,
                    tracker: boundingBox
                }}  
          />

                    <video
                ref={videoRef}
                style={{ position: 'absolute', top: 0, left: 0, zIndex: 9 }}
                width={window.innerWidth - 60}
                height={330}
                autoPlay
                muted
            />
  
            <canvas
                ref={canvasRef}
                style={{ position: 'absolute', top: 0, left: 0, zIndex: 99 }}
                width={window.innerWidth - 50} // Adjust width based on your video size
                height={330} // Adjust height based on your video size
            />
      

            
            </>

          // <BarcodeScannerComponent
          //     width={"100%"}
          //     height={300}
          //     onUpdate={(err, result) => {
          //       // alert(result.toString())
          //       if (result) SetSearch(result.toString());

          //     }}
              
          //   />
            
            :""}
            </div>
      </Col>
        {/* <Col xs={6} style={{paddingLeft:"5px"}}>
        <div style={{backgroundColor:"#ddd",borderRadius:"4px",height:"100%",position:"relative"}}>
          <div className='fingerPrintArea'>
            FingerPrint
          </div>
          </div>
      </Col> */}
        </Row>
        <Row className='topAreaScan' style={{paddingTop:"0px",paddingBottom:"30px"}}>
      <Col lg={4} sm={4} xs={6} style={{paddingRight:"5px",paddingBottom:selectedEmp && selectedEmp.type=="Daily Worker"?"5px":"15px"}}>
        <div className='innerDiv' style={{minHeight:100}}>
        <div className='fieldBottom'>
          <div className='fieldList'>{translate('Worker ID',language)} : <strong>{workerID?workerID:""}</strong></div>
          <div className='fieldList'>{translate('Name',language)} : <strong>{workerID && selectedEmp.name? selectedEmp.name:""} </strong></div>
          <div className='fieldList'>{translate('Agency',language)} : <strong>{workerID && selectedEmp.agency?selectedEmp.agency:""} </strong></div>
          <div className='fieldList'>{translate('Worker Type',language)} :  <strong>{workerID && selectedEmp.type?selectedEmp.type:''}</strong></div>
       </div>

        </div>


      </Col>
      <Col lg={4}  sm={4} xs={6} style={{paddingLeft:"5px",paddingBottom:selectedEmp && selectedEmp.type=="Daily Worker"?"5px":"15px"}}>
      <div className='innerDiv' style={{minHeight:100}}>
         <div className='fieldBottom'>
            <div className='fieldList'>{translate('Trace+ ID',language)} : <strong>{tagID}</strong></div>
            <div className='fieldList'>{translate('Worker Type',language)} : <strong>{tagID && selectedEmp.type?selectedEmp.type:""} </strong></div>
            <div className='fieldList'>{translate('Tag Type',language)} : <strong>{tagID && selectedEmp.type?"Personal Tag":""}</strong></div>
          </div>
      </div>

      </Col>
      {selectedEmp && selectedEmp.type=="Daily Worker"?
      <Col lg={4}  sm={4} xs={12}>
      <div className={selectedEmp.type=="Daily Worker"?'innerDiv boxHighlight':'innerDiv'}>
      <div className='form-field'>

        <label style={{display:"inline-block"}}>{translate('Function',language)} {
        
        
        
        selectedEmp.type=="Daily Worker" &&
         Function=='' && (tagID !='' && workerID !='') && onBoardEmp.findIndex(item=> item.worker_id== selectedEmp.worker_id || item.tag_serial== selectedEmp.tag_serial  )==-1?<span style={{color:"#ff0000"}}>(Required)</span>:""}</label>

          <Select
 
    placeholder={translate("Select Function",language)}
    onChange={(val)=>SetFunction(val)}
    value={Function}
    disabled={
      (selectedEmp && selectedEmp.type=="Regular Worker") ||
       ([...onBoardEmp].findIndex(item=>item.worker_id == selectedEmp.worker_id || item.tag_serial == selectedEmp.tag_serial ) >-1 ||
       tagID =='' || workerID==''
       )} 
    style={{display:"inline-block",marginBottom:"10px"}}

    >
    
 {
 selectedEmp && selectedEmp.type=="Daily Worker"? dailyfunction.map((item)=>{
                                    return <Option value={item.category_id} key={"Department"+item.category_id}>{item.category_name}</Option>
                                })
                              :""}
    </Select>




          {Function=="" && messageErrorFunction?<div className='errorMessage'>{messageErrorFunction}</div>:""}
       </div>
       </div>

       <div className='form-field formfielSubmit'>
          {selectedEmp && selectedEmp.type=="Daily Worker"?
          <input type="submit" className='btn btn-submit' value={nonCheckout.findIndex(item=> item.worker_id== selectedEmp.worker_id || item.tag_serial== selectedEmp.tag_serial ) > -1?translate("Check-Out",language):onBoardEmp.findIndex(item=>(item.worker_id == selectedEmp.worker_id || item.tag_serial== selectedEmp.tag_serial)  && new Date(`${moment(item.check_in).format('YYYY-MM-DD HH:mm')}`) > new Date(`${moment(workingHourDate.start).format('YYYY-MM-DD HH:mm')}`) &&  ( item.check_out!='') )  == -1?translate("Check-In",language):translate("Check-Out",language)} onClick={Addhandler} disabled={workerID=='' || tagID=='' || !apiEnable ||  (Function=="" && onBoardEmp.findIndex(item=>item.worker_id == selectedEmp.worker_id ||item.tag_serial== selectedEmp.tag_serial )== -1)}/>:null
          }      
      </div>
 
      </Col>:""}


    </Row>
      <audio ref={checkInSucessRef} src={SoundCheckInSuccess}  />
      <audio ref={checkInFailRef} src={SoundCheckInFailed}  />
      <audio ref={checkOutSucessRef} src={SoundCheckOutSuccess}  />
      <audio ref={checkOutFailRef} src={SoundCheckOutFailed}  />
      <audio ref={earlyCheckoutRef} src={SoundEarlyCheckout}  />
      <audio ref={validTagSerialRef} src={SoundValiDTagSerial}  />
      <audio ref={validWorkerIDRef} src={SoundValidWokerID}  />
      <audio ref={successRef} src={SoundSuccess}  />
      <audio ref={failedRef} src={SoundFailed}  />
      
      </div>
    </div>


        </div>
        </div>



    </div>
              )
    }

}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {  })(withRouter(DashboardMobile))
