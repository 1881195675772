import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Aside from './aside';


import traceplusLogo from '../assets/images/logo.png';
import safeairlogo from '../assets/images/safeair-logo-white.png'

import SpinnerLoader from '../assets/images/Spinner Loader.gif'




function LeftSideBar(props) {

    const [showLoader, updateShowLoader] = useState(false)
    const [showLogout, setShowLogout] = useState(false)
    const [showLogoutTimer, setShowLogoutTimer] = useState(false)
    const [logoutTimer30, setlogoutTimer30] = useState(10);
    const [resetTimer,SetRestTimer]=useState(false);


    let manufacturer = localStorage.getItem('manufacturer');
    //let userDetails = JSON.parse(localStorage.getItem('userLoginDetailsGateway'))
    let userLogin =localStorage.getItem('isLoggedInGateway')
    let timerIdle = null;
    let resetT;
    function handleLogout() {

        updateShowLoader(true)
        localStorage.removeItem('isLoggedInGateway')
        localStorage.removeItem('userLoginDetailsGateway')
        props.history.push(`/login`)
    
    }

 

function ToggledMenu(){
    props.ToggledMenu()
}

    return (
        <React.Fragment>
            <div className={props.menuActive?"main-sidebar main-sidebar-sticky side-menu":"main-sidebar main-sidebar-sticky side-menu menu-active"}>
                
                <div className={manufacturer!=null && manufacturer!='' && manufacturer=='SafeAir'?"sidemenu-logo log-safeair":"sidemenu-logo"}>
					<a className="main-logo" href="/dashboard">
                        {manufacturer!=null && manufacturer!='' && manufacturer=='SafeAir'? 
                    <img
                        src={safeairlogo} style={{display:"block"}}
                    />:<img
                    src={traceplusLogo} style={{display:"block"}}
                /> }
					</a>
				</div>
  

                     <Aside ToggledMenu={ToggledMenu}/>
                {/* <div className="LogoutDiv" onClick={handleLogoutModal}>
                    <span>Logout</span>
                </div> */}
            </div>


          
            {
                showLoader ?
                    <div className="wholePageLoaderMainDiv">
                        <img src={SpinnerLoader} />
                    </div> : ''
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    language: state.dashboard.selectedLangaugeValue
})

export default connect(mapStateToProps, {})(withRouter(LeftSideBar))

